import React, { lazy } from 'react'

const ToDoPerformance = lazy(() =>
  import('./Todo/ToDoPerformance').then(module => ({
    default: module.ToDoPerformance,
  }))
)

const PerformanceApprovalHistory = lazy(() =>
  import('./Todo/PerformanceApprovalHistory').then(module => ({
    default: module.PerformanceApprovalHistory,
  }))
)

const PerformanceManagement = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PerformanceManagement').then(module => ({
    default: module.PerformanceManagement,
  }))
)

const FormItemRatingSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FormItemRatingSetup/FormItemRatingSetupListing'
  ).then(module => ({
    default: module.FormItemRatingSetupListing,
  }))
)

const FormItemRatingSetupForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FormItemRatingSetup/FormItemRatingSetupForm'
  ).then(module => ({
    default: module.FormItemRatingSetupForm,
  }))
)

const ReviewGradingTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewGradingTable/ReviewGradingTableListing'
  ).then(module => ({
    default: module.ReviewGradingTableListing,
  }))
)

const ReviewGradingTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewGradingTable/ReviewGradingTableForm'
  ).then(module => ({
    default: module.ReviewGradingTableForm,
  }))
)

const ReviewPolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewPolicy/ReviewPolicyListing').then(
    module => ({
      default: module.ReviewPolicyListing,
    })
  )
)

const ReviewPolicyDetails = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewPolicy/ReviewPolicyDetails').then(
    module => ({
      default: module.ReviewPolicyDetails,
    })
  )
)

const ReviewPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewPolicy/ReviewPolicyForm').then(
    module => ({
      default: module.ReviewPolicyForm,
    })
  )
)

const KpiCategoryListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPICategory/KpiCategoryListing').then(
    module => ({
      default: module.KpiCategoryListing,
    })
  )
)

const KpiItemsListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsListing').then(
    module => ({
      default: module.KpiItemsListing,
    })
  )
)

const KpiItemsDetails = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsDetails').then(
    module => ({
      default: module.KpiItemsDetails,
    })
  )
)

const KpiItemsForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsForm').then(module => ({
    default: module.KpiItemsForm,
  }))
)

const KpiItemsView = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsView').then(module => ({
    default: module.KpiItemsView,
  }))
)

const KPIPredefinedTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateListing'
  ).then(module => ({
    default: module.KPIPredefinedTemplateListing,
  }))
)

const KPIPredefinedTemplateForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateForm'
  ).then(module => ({
    default: module.KPIPredefinedTemplateForm,
  }))
)

const KPIPredefinedTemplateDetailsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateDetailsForm'
  ).then(module => ({
    default: module.KPIPredefinedTemplateDetailsForm,
  }))
)

const KPIPredefinedTemplateView = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateView'
  ).then(module => ({
    default: module.KPIPredefinedTemplateView,
  }))
)

const KPIProcessPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIProcessPolicy/KPIProcessPolicyListing'
  ).then(module => ({
    default: module.KPIProcessPolicyListing,
  }))
)

const KPIProcessPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIProcessPolicy/KPIProcessPolicyForm'
  ).then(module => ({
    default: module.KPIProcessPolicyForm,
  }))
)

const KPIProcessPolicyDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIProcessPolicy/KPIProcessPolicyDetails'
  ).then(module => ({
    default: module.KPIProcessPolicyDetails,
  }))
)

const ReviewItemsListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewItems/ReviewItemsListing').then(
    module => ({
      default: module.ReviewItemsListing,
    })
  )
)

const ReviewItemsForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewItems/ReviewItemsForm').then(
    module => ({
      default: module.ReviewItemsForm,
    })
  )
)

const ReviewTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewTemplate/ReviewTemplateListing'
  ).then(module => ({
    default: module.ReviewTemplateListing,
  }))
)

const ReviewTemplateForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewTemplate/ReviewTemplateForm').then(
    module => ({
      default: module.ReviewTemplateForm,
    })
  )
)

const ReviewTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewTemplate/ReviewTemplateDetails'
  ).then(module => ({
    default: module.ReviewTemplateDetails,
  }))
)

const ReviewProcessPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewProcessPolicy/ReviewProcessPolicyListing'
  ).then(module => ({
    default: module.ReviewProcessPolicyListing,
  }))
)

const ReviewProcessPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewProcessPolicy/ReviewProcessPolicyForm'
  ).then(module => ({
    default: module.ReviewProcessPolicyForm,
  }))
)

const ReviewProcessPolicyDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewProcessPolicy/ReviewProcessPolicyDetails'
  ).then(module => ({
    default: module.ReviewProcessPolicyDetails,
  }))
)

const ReviewFormTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewFormTemplate/ReviewFormTemplateListing'
  ).then(module => ({
    default: module.ReviewFormTemplateListing,
  }))
)

const ReviewFormTemplateForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewFormTemplate/ReviewFormTemplateForm'
  ).then(module => ({
    default: module.ReviewFormTemplateForm,
  }))
)

const ReviewFormTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewFormTemplate/ReviewFormTemplateDetails'
  ).then(module => ({
    default: module.ReviewFormTemplateDetails,
  }))
)

const ReviewGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewGroup/ReviewGroupListing').then(
    module => ({
      default: module.ReviewGroupListing,
    })
  )
)

const RewardGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RewardGroup/RewardGroupListing').then(
    module => ({
      default: module.RewardGroupListing,
    })
  )
)

const PerformanceManagementListing = lazy(() =>
  import('./PerformanceManagement/PerformanceManagementListing').then(
    module => ({
      default: module.PerformanceManagementListing,
    })
  )
)

const PerformanceManagementCompanySetting = lazy(() =>
  import(
    './PerformanceManagement/CompanySetting/PerformanceManagementCompanySetting'
  ).then(module => ({
    default: module.PerformanceManagementCompanySetting,
  }))
)

const ReviewGroupMatrixListing = lazy(() =>
  import(
    './PerformanceManagement/CompanySetting/ReviewGroupMatrix/ReviewGroupMatrixListing'
  ).then(module => ({
    default: module.ReviewGroupMatrixListing,
  }))
)

const RewardGroupMatrixListing = lazy(() =>
  import(
    './PerformanceManagement/CompanySetting/RewardGroupMatrix/RewardGroupMatrixListing'
  ).then(module => ({
    default: module.RewardGroupMatrixListing,
  }))
)

const OrganizationalGoalsListing = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalGoals/OrganizationalGoalsListing'
  ).then(module => ({
    default: module.OrganizationalGoalsListing,
  }))
)

const OrganizationalGoalsListingByCategory = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalGoals/OrganizationalGoalsListingByCategory'
  ).then(module => ({
    default: module.OrganizationalGoalsListingByCategory,
  }))
)

const OrganizationalGoalsForm = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalGoals/OrganizationalGoalsForm'
  ).then(module => ({
    default: module.OrganizationalGoalsForm,
  }))
)

const OrgKpiListing = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalKpiPlanning/OrgKpiListing'
  ).then(module => ({
    default: module.OrgKpiListing,
  }))
)

const OrgKpiViewForm = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalKpiPlanning/OrgKpiViewForm'
  ).then(module => ({
    default: module.OrgKpiViewForm,
  }))
)
const KpiCategoryWithOrgKpiListing = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalKpiPlanning/KpiCategoryWithOrgKpiListing'
  ).then(module => ({
    default: module.KpiCategoryWithOrgKpiListing,
  }))
)

const OrgKpiDetailsByKpiCategoryListing = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalKpiPlanning/OrgKpiDetailsByKpiCategoryListing'
  ).then(module => ({
    default: module.OrgKpiDetailsByKpiCategoryListing,
  }))
)

const OrgKpiDetailForm = lazy(() =>
  import(
    './PerformanceManagement/OrganizationalKpiPlanning/OrgKpiDetailForm'
  ).then(module => ({
    default: module.OrgKpiDetailForm,
  }))
)

const KPIExercise = lazy(() =>
  import('./PerformanceManagement/KPIExercise/KPIExercise').then(module => ({
    default: module.KPIExercise,
  }))
)

const KPIExerciseSetupListing = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseSetup/KPIExerciseSetupListing'
  ).then(module => ({
    default: module.KPIExerciseSetupListing,
  }))
)

const KPIExerciseSetupForm = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseSetup/KPIExerciseSetupForm'
  ).then(module => ({
    default: module.KPIExerciseSetupForm,
  }))
)

const KPIExerciseSetupView = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseSetup/KPIExerciseSetupView'
  ).then(module => ({
    default: module.KPIExerciseSetupView,
  }))
)

const KPIExerciseListing = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseListing/KPIExerciseListing'
  ).then(module => ({
    default: module.KPIExerciseListing,
  }))
)

const KPIExerciseCatForm = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseListing/KPIExerciseCatForm'
  ).then(module => ({
    default: module.KPIExerciseCatForm,
  }))
)

const KPIExerciseItemForm = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseListing/KPIExerciseItemForm'
  ).then(module => ({
    default: module.KPIExerciseItemForm,
  }))
)

const KPIExerciseDetailsEditForm = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseListing/KPIExerciseDetailsEditForm'
  ).then(module => ({
    default: module.KPIExerciseDetailsEditForm,
  }))
)

const KPIExerciseDetailsView = lazy(() =>
  import(
    './PerformanceManagement/KPIExercise/KPIExerciseListing/KPIExerciseDetailsView'
  ).then(module => ({
    default: module.KPIExerciseDetailsView,
  }))
)

const EmployeeKpiRecordsListing = lazy(() =>
  import(
    './PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsListing'
  ).then(module => ({
    default: module.EmployeeKpiRecordsListing,
  }))
)

const EmployeeKpiRecordsView = lazy(() =>
  import(
    './PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsView'
  ).then(module => ({
    default: module.EmployeeKpiRecordsView,
  }))
)

const EmployeeKpiRecordsViewItem = lazy(() =>
  import(
    './PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsViewItem'
  ).then(module => ({
    default: module.EmployeeKpiRecordsViewItem,
  }))
)

const EmployeeKpiRecordsViewItemDetails = lazy(() =>
  import(
    './PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsViewItemDetails'
  ).then(module => ({
    default: module.EmployeeKpiRecordsViewItemDetails,
  }))
)

const ReviewExercise = lazy(() =>
  import('./PerformanceManagement/ReviewExercise/ReviewExercise').then(
    module => ({
      default: module.ReviewExercise,
    })
  )
)

const ReviewExerciseSetupListing = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseSetup/ReviewExerciseSetupListing'
  ).then(module => ({
    default: module.ReviewExerciseSetupListing,
  }))
)

const ReviewExerciseSetupForm = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseSetup/ReviewExerciseSetupForm'
  ).then(module => ({
    default: module.ReviewExerciseSetupForm,
  }))
)

const ReviewExerciseSetupDetail = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseSetup/ReviewExerciseSetupDetail'
  ).then(module => ({
    default: module.ReviewExerciseSetupDetail,
  }))
)

const ReviewExercisePeerRegistrationSetup = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseSetup/ReviewExercisePeerRegistrationSetup'
  ).then(module => ({
    default: module.ReviewExercisePeerRegistrationSetup,
  }))
)

const ReviewExerciseListing = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseListing/ReviewExerciseListing'
  ).then(module => ({
    default: module.ReviewExerciseListing,
  }))
)

const ReviewExerciseForm = lazy(() =>
  import(
    './PerformanceManagement/ReviewExercise/ReviewExerciseListing/ReviewExerciseForm'
  ).then(module => ({
    default: module.ReviewExerciseForm,
  }))
)

const PerformanceSubordinatePerformanceReview = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceReview/SubordinatePerformanceReview'
  ).then(module => ({
    default: module.SubordinatePerformanceReview,
  }))
)
const EmployeeKpiRecords = lazy(() =>
  import('./PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecords').then(
    module => ({
      default: module.EmployeeKpiRecords,
    })
  )
)

const EmployeeReviewRecords = lazy(() =>
  import(
    './PerformanceManagement/EmployeeReviewRecords/EmployeeReviewRecords'
  ).then(module => ({
    default: module.EmployeeReviewRecords,
  }))
)

const MyPerformanceListing = lazy(() =>
  import('./EmployeeMyPerformance/MyPerformanceListing').then(module => ({
    default: module.MyPerformanceListing,
  }))
)

const MyKpiListing = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiListing').then(module => ({
    default: module.MyKpiListing,
  }))
)

const MyKpiRepository = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiRepository').then(module => ({
    default: module.MyKpiRepository,
  }))
)

const MyKpiRepositoryEdit = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiRepositoryEdit').then(module => ({
    default: module.MyKpiRepositoryEdit,
  }))
)

const MyKpiCatForm = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiCatForm').then(module => ({
    default: module.MyKpiCatForm,
  }))
)

const MyKpiItemForm = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiItemForm').then(module => ({
    default: module.MyKpiItemForm,
  }))
)

const MyKpiDetailEditForm = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiDetailEditForm').then(module => ({
    default: module.MyKpiDetailEditForm,
  }))
)

const MyKpiDetailsView = lazy(() =>
  import('./EmployeeMyPerformance/MyKPI/MyKpiDetailsView').then(module => ({
    default: module.MyKpiDetailsView,
  }))
)

const MyPerformanceReviewListing = lazy(() =>
  import(
    './EmployeeMyPerformance/MyPerformanceReview/MyPerformanceReviewListing'
  ).then(module => ({
    default: module.MyPerformanceReviewListing,
  }))
)

const MyPerformanceReviewForm = lazy(() =>
  import(
    './EmployeeMyPerformance/MyPerformanceReview/MyPerformanceReviewForm'
  ).then(module => ({
    default: module.MyPerformanceReviewForm,
  }))
)

const MyReviewActionListing = lazy(() =>
  import('./EmployeeMyPerformance/MyReviewAction/MyReviewActionListing').then(
    module => ({
      default: module.MyReviewActionListing,
    })
  )
)

const MyReviewActionForm = lazy(() =>
  import('./EmployeeMyPerformance/MyReviewAction/MyReviewActionForm').then(
    module => ({
      default: module.MyReviewActionForm,
    })
  )
)

const MyPerformanceResultListing = lazy(() =>
  import(
    './EmployeeMyPerformance/MyPerformanceResults/MyPerformanceResultListing'
  ).then(module => ({
    default: module.MyPerformanceResultListing,
  }))
)

const SubordinatePerformanceListing = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/SubordinatePerformanceListing'
  ).then(module => ({
    default: module.SubordinatePerformanceListing,
  }))
)

const PerformanceSubordinateListing = lazy(() =>
  import('./Subordinates/PerformanceManagement/SubordinateListing').then(
    module => ({
      default: module.SubordinateListing,
    })
  )
)

const SubordinateKpiListing = lazy(() =>
  import('./Subordinates/PerformanceManagement/SubordinateKpiListing').then(
    module => ({
      default: module.SubordinateKpiListing,
    })
  )
)

const SubordinateKpiCatForm = lazy(() =>
  import('./Subordinates/PerformanceManagement/SubordinateKpiCatForm').then(
    module => ({
      default: module.SubordinateKpiCatForm,
    })
  )
)

const SubordinateKpiItemForm = lazy(() =>
  import('./Subordinates/PerformanceManagement/SubordinateKpiItemForm').then(
    module => ({
      default: module.SubordinateKpiItemForm,
    })
  )
)

const SubordinateKpiDetailEditForm = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/SubordinateKpiDetailEditForm'
  ).then(module => ({
    default: module.SubordinateKpiDetailEditForm,
  }))
)

const SubordinateKpiDetailsView = lazy(() =>
  import('./Subordinates/PerformanceManagement/SubordinateKpiDetailsView').then(
    module => ({
      default: module.SubordinateKpiDetailsView,
    })
  )
)

const PerformanceSubordinatePerformanceReviewListing = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceReview/SubordinatePerformanceReviewListing'
  ).then(module => ({
    default: module.SubordinatePerformanceReviewListing,
  }))
)

const PerformanceSubordinatePerformanceReviewForm = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceReview/SubordinatePerformanceReviewForm'
  ).then(module => ({
    default: module.SubordinatePerformanceReviewForm,
  }))
)

const PerformanceSubordinateModerationListing = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceResults/ModerationListing'
  ).then(module => ({
    default: module.ModerationListing,
  }))
)

const SubordinateModerationExerciseProcess = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceResults/SubordinateModerationExerciseProcess'
  ).then(module => ({
    default: module.SubordinateModerationExerciseProcess,
  }))
)

const SubordinateModerationExerciseProcessView = lazy(() =>
  import(
    './Subordinates/PerformanceManagement/PerformanceResults/SubordinateModerationExerciseProcessView'
  ).then(module => ({
    default: module.SubordinateModerationExerciseProcessView,
  }))
)

const PerformanceModeration = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/PerformanceModeration'
  ).then(module => ({
    default: module.PerformanceModeration,
  }))
)

const ModerationExerciseListing = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationExercise/ModerationExerciseListing'
  ).then(module => ({
    default: module.ModerationExerciseListing,
  }))
)

const ModerationExerciseForm = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationExercise/ModerationExerciseForm'
  ).then(module => ({
    default: module.ModerationExerciseForm,
  }))
)

const ModerationExerciseDetailsForm = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationExercise/ModerationExerciseDetailsForm'
  ).then(module => ({
    default: module.ModerationExerciseDetailsForm,
  }))
)

const ModerationGroupSettingForm = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationExercise/ModerationGroupSettingForm'
  ).then(module => ({
    default: module.ModerationGroupSettingForm,
  }))
)

const ModerationExerciseView = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationExercise/ModerationExerciseView'
  ).then(module => ({
    default: module.ModerationExerciseView,
  }))
)

const ModerationListing = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationListing/ModerationListing'
  ).then(module => ({
    default: module.ModerationListing,
  }))
)

const ModerationExerciseProcess = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationListing/ModerationExerciseProcess'
  ).then(module => ({
    default: module.ModerationExerciseProcess,
  }))
)

const ModerationExerciseProcessView = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationListing/ModerationExerciseProcessView'
  ).then(module => ({
    default: module.ModerationExerciseProcessView,
  }))
)

const ModerationReviewRecords = lazy(() =>
  import(
    './PerformanceManagement/PerformanceModeration/ModerationListing/ModerationReviewRecords'
  ).then(module => ({
    default: module.ModerationReviewRecords,
  }))
)

const PerformanceRewarding = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/PerformanceRewarding'
  ).then(module => ({
    default: module.PerformanceRewarding,
  }))
)

const RewardSimulationListing = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/RewardSimulation/RewardSimulationListing'
  ).then(module => ({
    default: module.RewardSimulationListing,
  }))
)

const RewardSimulationForm = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/RewardSimulation/RewardSimulationForm'
  ).then(module => ({
    default: module.RewardSimulationForm,
  }))
)

const RewardSimulationDetailsForm = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/RewardSimulation/RewardSimulationDetailsForm'
  ).then(module => ({
    default: module.RewardSimulationDetailsForm,
  }))
)

const RewardSimulationView = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/RewardSimulation/RewardSimulationView'
  ).then(module => ({
    default: module.RewardSimulationView,
  }))
)

const SimulationResultListing = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/EmployeeSimulationResult/SimulationResultListing'
  ).then(module => ({
    default: module.SimulationResultListing,
  }))
)

const SimulationResultProcess = lazy(() =>
  import(
    './PerformanceManagement/PerformanceRewarding/EmployeeSimulationResult/SimulationResultProcess'
  ).then(module => ({
    default: module.SimulationResultProcess,
  }))
)

const HomeRoutes = [
  {
    props: {
      exact: true,
      path: '/home/todo/performance',
    },
    component: <ToDoPerformance />,
  },
  {
    props: {
      exact: true,
      path: '/home/todo/performance/:PerformanceRequestType',
    },
    component: <ToDoPerformance />,
  },
  {
    props: { path: '/home/todo/PerformanceApprovalHistory' },
    component: <PerformanceApprovalHistory />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PerformanceManagement',
    },
    component: <PerformanceManagement />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/FormItemRatingSetup/listing',
    },
    component: <FormItemRatingSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/FormItemRatingSetup/listing/form',
    },
    component: <FormItemRatingSetupForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGradingTable/listing',
    },
    component: <ReviewGradingTableListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGradingTable/listing/form',
    },
    component: <ReviewGradingTableForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewPolicy/listing',
    },
    component: <ReviewPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewPolicy/listing/details',
    },
    component: <ReviewPolicyDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewPolicy/listing/form',
    },
    component: <ReviewPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGroupL/listing/',
    },
    component: <ReviewGroupListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpicategorylisting',
    },
    component: <KpiCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpiitemslisting',
    },
    component: <KpiItemsListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpiitemslisting/kpiitemsdetails',
    },
    component: <KpiItemsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/kpiitemslisting/kpiitemsdetails/kpiitemsform',
    },
    component: <KpiItemsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpiitemslisting/kpiitemsdetails/view',
    },
    component: <KpiItemsView />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpitemplatelisting',
    },
    component: <KPIPredefinedTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpitemplatelisting/kpitemplateform',
    },
    component: <KPIPredefinedTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/kpitemplatelisting/kpitemplateform/kpitemplateformdetails',
    },
    component: <KPIPredefinedTemplateDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpitemplatelisting/kpitemplateview',
    },
    component: <KPIPredefinedTemplateView />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/KPIProcessPolicy/listing',
    },
    component: <KPIProcessPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/KPIProcessPolicy/listing/form',
    },
    component: <KPIProcessPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/KPIProcessPolicy/listing/details',
    },
    component: <KPIProcessPolicyDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewitemslisting',
    },
    component: <ReviewItemsListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewitemsform',
    },
    component: <ReviewItemsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewtemplatelisting',
    },
    component: <ReviewTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/reviewtemplatelisting/reviewtemplateform',
    },
    component: <ReviewTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/reviewtemplatelisting/reviewtemplatedetails',
    },
    component: <ReviewTemplateDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewProcessPolicy/listing',
    },
    component: <ReviewProcessPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewProcessPolicy/listing/form',
    },
    component: <ReviewProcessPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewProcessPolicy/listing/details',
    },
    component: <ReviewProcessPolicyDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewFormTemplate/listing',
    },
    component: <ReviewFormTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewFormTemplate/listing/form',
    },
    component: <ReviewFormTemplateForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewFormTemplate/listing/details',
    },
    component: <ReviewFormTemplateDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/rewardgrouplisting',
    },
    component: <RewardGroupListing />,
  },
  {
    props: { exact: true, path: '/PerformanceManagementListing' },
    component: <PerformanceManagementListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/CompanySetting/PerformanceManagementCompanySetting',
    },
    component: <PerformanceManagementCompanySetting />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/CompanySetting/ReviewGroupMatrix/listing',
    },
    component: <ReviewGroupMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/CompanySetting/RewardGroupMatrix/listing',
    },
    component: <RewardGroupMatrixListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrganizationalGoalsListing/listing',
    },
    component: <OrganizationalGoalsListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrganizationalGoalsListing/category',
    },
    component: <OrganizationalGoalsListingByCategory />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrganizationalGoalsListing/form',
    },
    component: <OrganizationalGoalsForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrgKpiListing/listing',
    },
    component: <OrgKpiListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrgKpiListing/viewForm',
    },
    component: <OrgKpiViewForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrgKpiListing/kpiCategory',
    },
    component: <KpiCategoryWithOrgKpiListing />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrgKpiListing/details',
    },
    component: <OrgKpiDetailsByKpiCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/OrgKpiListing/detailForm',
    },
    component: <OrgKpiDetailForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise',
    },
    component: <KPIExercise />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KPIExerciseListing',
    },
    component: <KPIExerciseListing />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KPIExerciseSetupListing',
    },
    component: <KPIExerciseSetupListing />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KPIExerciseSetupForm',
    },
    component: <KPIExerciseSetupForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KPIExerciseSetupView',
    },
    component: <KPIExerciseSetupView />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KpiCatForm',
    },
    component: <KPIExerciseCatForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/KPIExercise/KpiCatForm/KpiItemForm',
    },
    component: <KPIExerciseItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/KPIExercise/KpiCatForm/KpiItemForm/KpiDetails',
    },
    component: <KPIExerciseDetailsEditForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/KPIExercise/KpiCatForm/KpiItemForm/KpiDetailsView',
    },
    component: <KPIExerciseDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecords',
    },
    component: <EmployeeKpiRecords />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsListing',
    },
    component: <EmployeeKpiRecordsListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsView',
    },
    component: <EmployeeKpiRecordsView />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsView/item',
    },
    component: <EmployeeKpiRecordsViewItem />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/EmployeeKpiRecords/EmployeeKpiRecordsView/item/details',
    },
    component: <EmployeeKpiRecordsViewItemDetails />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise',
    },
    component: <ReviewExercise />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise/ReviewExerciseSetupListing',
    },
    component: <ReviewExerciseSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise/ReviewExerciseSetupForm',
    },
    component: <ReviewExerciseSetupForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise/ReviewExerciseSetupDetail',
    },
    component: <ReviewExerciseSetupDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/ReviewExercise/ReviewExerciseSetupListing/PeerRegistration',
    },
    component: <ReviewExercisePeerRegistrationSetup />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise/ReviewExerciseListing',
    },
    component: <ReviewExerciseListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/ReviewExercise/ReviewExerciseListing/Form',
    },
    component: <ReviewExerciseForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/EmployeeReviewRecords',
    },
    component: <EmployeeReviewRecords />,
  },

  {
    props: { exact: true, path: '/MyPerformance/Listing' },
    component: <MyPerformanceListing />,
  },
  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpi' },
    component: <MyKpiListing />,
  },
  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpi/KpiCatForm' },
    component: <MyKpiCatForm />,
  },
  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpi/KpiItemForm' },
    component: <MyKpiItemForm />,
  },
  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpi/DetailsForm' },
    component: <MyKpiDetailEditForm />,
  },
  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpi/DetailsView' },
    component: <MyKpiDetailsView />,
  },

  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpiRepository' },
    component: <MyKpiRepository />,
  },

  {
    props: { exact: true, path: '/MyPerformance/Listing/MyKpiRepositoryEdit' },
    component: <MyKpiRepositoryEdit />,
  },
  {
    props: {
      exact: true,
      path: '/MyPerformance/Listing/MyPerformanceReview',
    },
    component: <MyPerformanceReviewListing />,
  },
  {
    props: {
      exact: true,
      path: '/MyPerformance/Listing/MyPerformanceReview/Form',
    },
    component: <MyPerformanceReviewForm />,
  },
  {
    props: {
      exact: true,
      path: '/MyPerformance/Listing/MyReviewAction',
    },
    component: <MyReviewActionListing />,
  },
  {
    props: {
      exact: true,
      path: '/MyPerformance/Listing/MyReviewAction/Form',
    },
    component: <MyReviewActionForm />,
  },
  {
    props: {
      exact: true,
      path: '/MyPerformance/Listing/MyPerformanceResults',
    },
    component: <MyPerformanceResultListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/performanceListing',
    },
    component: <SubordinatePerformanceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate',
    },
    component: <PerformanceSubordinateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/kpiListing',
    },
    component: <SubordinateKpiListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/kpiListing/KpiCatForm',
    },
    component: <SubordinateKpiCatForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/kpiListing/KpiItemForm',
    },
    component: <SubordinateKpiItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/kpiListing/DetailsForm',
    },
    component: <SubordinateKpiDetailEditForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/kpiListing/DetailsView',
    },
    component: <SubordinateKpiDetailsView />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/PerformanceReview/subordinate',
    },
    component: <PerformanceSubordinatePerformanceReview />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/performanceReviewListing',
    },
    component: <PerformanceSubordinatePerformanceReviewListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/subordinate/performanceReviewForm',
    },
    component: <PerformanceSubordinatePerformanceReviewForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/moderationListing',
    },
    component: <PerformanceSubordinateModerationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/moderationListing/ProcessListing',
    },
    component: <SubordinateModerationExerciseProcess />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/performanceListing/moderationListing/ProcessListing/:ModerationExerciseRecordID',
    },
    component: <SubordinateModerationExerciseProcessView />,
  },

  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration',
    },
    component: <PerformanceModeration />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/Listing',
    },
    component: <ModerationExerciseListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/Form',
    },
    component: <ModerationExerciseForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/DetailsForm',
    },
    component: <ModerationExerciseDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/GroupSettingForm',
    },
    component: <ModerationGroupSettingForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/View',
    },
    component: <ModerationExerciseView />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceModeration/ModerationListing',
    },
    component: <ModerationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/PerformanceModeration/ModerationExerciseProcess',
    },
    component: <ModerationExerciseProcess />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/PerformanceManagement/PerformanceModeration/ModerationExerciseProcessView',
  //   },
  //   component: <ModerationExerciseProcessView />,
  // },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/PerformanceModeration/ModerationExerciseProcess/:ModerationExerciseRecordID',
    },
    component: <ModerationExerciseProcessView />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceManagement/PerformanceModeration/ModerationExerciseProcess/:ModerationExerciseRecordID/ReviewRecords',
    },
    component: <ModerationReviewRecords />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceManagement/PerformanceRewarding',
    },
    component: <PerformanceRewarding />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/RewardSimulationListing',
    },
    component: <RewardSimulationListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/RewardSimulationForm/New',
    },
    component: <RewardSimulationForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/RewardSimulationForm/Edit',
    },
    component: <RewardSimulationForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/PerformanceRewarding/RewardSimulation/RewardSimulationDetailsForm',
    },
    component: <RewardSimulationDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/RewardSimulationView',
    },
    component: <RewardSimulationView />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/SimulationResultListing',
    },
    component: <SimulationResultListing />,
  },
  {
    props: {
      exact: true,
      path: '/PerformanceRewarding/RewardSimulation/SimulationResultProcess',
    },
    component: <SimulationResultProcess />,
  },
]

export default HomeRoutes
